import React, { lazy } from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { Navigate } from 'react-router-dom';

// Lazy load components
const SignInEmailPassword = lazy(() => import('./components/SignInEmailPassword'));

const routes = (role, user) => [
  {
    path: '/login',
    element: <SignInEmailPassword />
  },
  
  {
    path: '/why',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/Why')))} />
  },
  {
    path: '/thebuildingmachine',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TheBuildingMachine')))} />
  },
  {
    path: '/homes',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/Homes')), { userRole: role, user: user})} />
  },



  {
    path: '/tbt-os',
    element: <Navigate to="/tbt-os/projects" />
  },
  {
    path: '/tbt-os/projects',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectList')), { userRole: role, user: user})} />
  },
      {
        path: '/tbt-os/projects/:projectId/general',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/General')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/costs',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/Costs')), { userRole: role, user: user } )} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectteam',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectTeam')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectsections',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectSections')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectmachine',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectMachine')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectseptic',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectSeptic')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectsite',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectSite')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/projectpods',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectPods')), { userRole: role })} />
      },
      {
        path: '/tbt-os/projects/:projectId/ProjectDesign',
        element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/ProjectDetail/ProjectDesign')), { userRole: role })} />
      },


  {
    path: '/tbt-os/machinelist',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineList')), { userRole: role, user: user })} />
  },
  {
    path: '/tbt-os/machinelist/:machineId/machinegeneral',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineDetail/MachineGeneral')), { userRole: role })} />
  },
  {
    path: '/tbt-os/machinelist/:machineId/machineuse',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineDetail/MachineUse')), { userRole: role })} />
  },
  {
    path: '/tbt-os/machinelist/:machineId/machineprojects',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineDetail/MachineProjects')), { userRole: role })} />
  },
  {
    path: '/tbt-os/machinelist/:machineId/machineteam',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineDetail/MachineTeam')), { userRole: role })} />
  },
  {
    path: '/tbt-os/machinelist/:machineId/machinefab',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/MachineDetail/MachineFab')), { userRole: role })} />
  },
  {
    path: '/tbt-os/team/:userId/teammachines',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/TeamDetail/TeamMachines')), { userRole: role })} />
  },
  {
    path: '/tbt-os/team/:userId/teamdetails',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/TeamDetail/TeamDetails')), { userRole: role })} />
  },
  {
    path: '/tbt-os/team/:userId/teamprojects',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/TeamDetail/TeamProjects')), { userRole: role })} />
  },
  {
    path: '/tbt-os/team',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/Team')), { userRole: role, user: user })} />
  },
  // {
  // path: '/tbt-os/siteslist',
  // element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SitesList')), { userRole: role })} />
  // }, 
  // {   
  // path: '/tbt-os/podslist',
  // element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodsList')), { userRole: role })} />
  // },  
  {  
  path: '/tbt-os/sectionslist',
  element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SectionsList')), { userRole: role })} />
  },
  {  
  path: '/tbt-os/companieslist',
  element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/CompaniesList')), { userRole: role, user: user })} />
  },
      {  
      path: '/tbt-os/companieslist/:companyId/CompanyGeneral',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/CompanyDetail/CompanyGeneral')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/companieslist/:companyId/CompanyTeam',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/CompanyDetail/CompanyTeam')), { userRole: role })} />
      },

  {  
  path: '/tbt-os/podslist',
  element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodsList')), { userRole: role, user: user  })} />
  },
      {  
      path: '/tbt-os/podslist/:podId/PodGeneral',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodDetail/PodGeneral')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/podslist/:podId/PodTeam',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodDetail/PodTeam')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/podslist/:podId/PodFab',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodDetail/PodFab')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/podslist/:podId/PodProject',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/PodDetail/PodProject')), { userRole: role })} />
      },

  {  
  path: '/tbt-os/siteslist',
  element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SitesList')), { userRole: role, user: user })} />
  },
      {  
      path: '/tbt-os/siteslist/:siteId/SiteGeneral',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SiteDetail/SiteGeneral.js')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/siteslist/:siteId/SiteZoning',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SiteDetail/SiteZoning.js')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/siteslist/:siteId/SiteAcquisition',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SiteDetail/SiteAcquisition.js')), { userRole: role })} />
      },
      {  
      path: '/tbt-os/siteslist/:siteId/SiteProject',
      element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TBT-OS/SiteDetail/SiteProject.js')), { userRole: role })} />
      },


    

  {
    path: '/biz',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/Biz')))} />
  },
  {
    path: '/order-your-home/:projectId',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/OrderYourHome')), { userRole: role, user: user })} />
  },

  {
    path: '/privacypolicy',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/PrivacyPolicy')))} />
  },
  {
    path: '/termsofservice',
    element: <ProtectedRoute element={React.createElement(lazy(() => import('./components/TermsOfService')))} />
  },
  {
    path: '/',
    element: user ? <Navigate to="/thebuildingmachine" /> : <SignInEmailPassword />
  },





  
];

export default routes;
