// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // This sets the global theme to dark
    background: {
      default: '#000000', // Black background
      paper: '#000000', // Black background for paper elements
    },
    text: {
      primary: '#ffffff', // White text
    },
    primary: {
      main: '#ffffff', // Set primary color to white
    },
    action: {
      active: '#ffffff', // Ensure active elements like tabs are white
    },
  },
  typography: {
    allVariants: {
      color: '#ffffff', // Ensure all text is white
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff', // White text for buttons
          borderColor: '#ffffff', // White border for outlined buttons
          '&:hover': {
            borderColor: '#ffffff', // Ensure the border stays white on hover
          },
        },
        outlined: {
          borderColor: '#ffffff', // White border for outlined buttons
          color: '#ffffff', // White text for outlined buttons
        },
        contained: {
          backgroundColor: '#ffffff', // White background for contained buttons
          color: '#000000', // Black text for contrast
          '&:hover': {
            backgroundColor: '#e0e0e0', // Slightly darker white on hover
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#ffffff', // White underline for active tab
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff', // White text for tabs
          '&.Mui-selected': {
            color: '#ffffff', // White text for the selected tab
          },
        },
      },
    },
  },
});

export default theme;
