import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { getFirestore, doc, getDoc, collection, addDoc, Timestamp } from 'firebase/firestore';
import { UAParser } from 'ua-parser-js';
import Navbar from './components/Navbar';
import routes from './routes'; // Import the routes from the central file
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState('');
  const [initialRedirectDone, setInitialRedirectDone] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // To prevent setting state on unmounted component

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && isMounted) {
        setUser(user);
        const db = getFirestore();
        const docRef = doc(db, 'users', user.uid);

        const currentPage = window.location.pathname;
        const browserInfo = navigator.userAgent
        // Determine if the device is mobile
        // const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(browserInfo);
        // const screenWidth = window.screen.width;  // Get the screen width
        // const screenHeight = window.screen.height; 
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's time zone
        
   
        const userLanguage = navigator.language || 'unknown';
        const userLanguages = navigator.languages || ['unknown'];
        const connectionType = navigator.connection ? navigator.connection.effectiveType : 'unknown';
        const deviceMemory = navigator.deviceMemory || 'unknown'; // Typically returns in GB
        const screenOrientation = window.screen.orientation.type || 'unknown'; // e.g., 'portrait-primary', 'landscape-primary'
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;


        // Initialize UAParser and parse the user agent string
        const parser = new UAParser();
        const result = parser.getResult();
        
        // Extract the device information
        const deviceType = result.device.type || 'unknown';
        const deviceModel = result.device.model || 'unknown';
        const deviceOS = result.os.name || 'unknown';
        const osVersion = result.os.version || 'unknown'; // OS version, if needed

        // Get user roles
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && isMounted) {
            setRole(docSnap.data().role);
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }

        // Track user log in events
        try {
          // Reference the loginEvents subcollection within the user's document
          await addDoc(collection(db, `users/${user.uid}/loginEvents`), {
            timestamp: Timestamp.now(),
            page: window.location.pathname,
            currentPage: currentPage,
            deviceOS: deviceOS,
            deviceType: deviceType,
            browser: navigator.userAgent,
            isMobile: /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

            osVersion: osVersion,              // Operating System Version
            userLanguage: userLanguage,        // Preferred language
            connectionType: connectionType,    // Network connection type
            deviceMemory: deviceMemory,        // Device memory in GB
            screenOrientation: screenOrientation, // Screen orientation
            isTouchDevice: isTouchDevice 

          });
        } catch (error) {
          console.error('Error writing login events:', error);
        }

        // Redirect them if they just signed in
        console.log('in App.js turn this back to thebuildingmachine');
        if (!initialRedirectDone && isMounted) {
          setInitialRedirectDone(true);
          navigate('/thebuildingmachine');
          // navigate('/homes');
          // navigate('/tbt-os/projects');
        }
      } else if (isMounted) {
        setUser(null);
        setRole('');
        setInitialRedirectDone(false);
      }
    });

    return () => {
      isMounted = false; // Cleanup flag
      unsubscribe(); // Cleanup auth listener
    };
  }, [navigate, initialRedirectDone]);

  return (
    <div className="App">
      {user && <Navbar userRole={role} />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes(role, user).map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  );
}
