import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Avatar, Menu, MenuItem, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import logo from '../logo.svg'; // Ensure this path is correct
import { auth } from '../firebase';

const Navbar = ({ userRole }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.currentUser;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    auth.signOut();
    navigate('/login');
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const controlNavbar = () => {
    if (window.scrollY < 50) {
      setShow(true);
    } else if (window.scrollY > lastScrollY) {
      setShow(false);
    } else {
      setShow(true);
    }
    setLastScrollY(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  const isActive = (path) => location.pathname === path;

  const buttonStyles = {
    color: 'lightgray', // Light gray for non-selected tabs
    textDecoration: 'none',
    fontWeight: 'normal',
    height: '100%', // Make the button height match the navbar height
    textTransform: 'none', // Add this line to prevent all-caps
    '&.active': {
      color: 'white', // White color for the selected tab
      borderBottom: '2px solid white',
      borderRadius: '0px',
    },
  };

  const drawerItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/homes">
          <ListItemText primary="Homes" />
        </ListItem>
        <ListItem button component={Link} to="/thebuildingmachine">
          <ListItemText primary="the Building Machine" />
        </ListItem>
        <ListItem button component={Link} to="/biz">
          <ListItemText primary="Biz" />
        </ListItem>
        <ListItem button component={Link} to="/why">
          <ListItemText primary="Why" />
        </ListItem>
        <ListItem button component={Link} to="/tbt-os">
          <ListItemText primary="TBT OS" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          transform: show ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out',
          top: 0,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', height: 64 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <img src={logo} alt="Company Logo" style={{ height: 40, marginRight: 16 }} />
            <Typography variant="h6" component={Link} to="/" sx={{ color: 'inherit', textDecoration: 'none', mr: 2, flexGrow: 1 }}>
              the Building Train
            </Typography>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button
                component={Link}
                to="/homes"
                sx={{ ...buttonStyles, ...(isActive('/homes') && buttonStyles['&.active']) }}
              >
                Homes
              </Button>
              <Button
                component={Link}
                to="/thebuildingmachine"
                sx={{ ...buttonStyles, ...(isActive('/thebuildingmachine') && buttonStyles['&.active']) }}
              >
                TheBuildingMachine
              </Button>
              <Button
                component={Link}
                to="/biz"
                sx={{ ...buttonStyles, ...(isActive('/biz') && buttonStyles['&.active']) }}
              >
                Biz
              </Button>
              <Button
                component={Link}
                to="/why"
                sx={{ ...buttonStyles, ...(isActive('/why') && buttonStyles['&.active']) }}
              >
                Why
              </Button>
              <Typography variant="h5" sx={{ paddingLeft: '10px',color: 'inherit', textDecoration: 'none', mr: 2, flexGrow: 1 }}>
              |
            </Typography>
              <Button
                component={Link}
                to="/tbt-os"
                sx={{ ...buttonStyles, ...(isActive('/tbt-os') && buttonStyles['&.active']) }}
              >
                TBT OS
              </Button>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton color="inherit" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerItems}
              </Drawer>
            </Box>
          </Box>

          <Box>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <Avatar>{user?.email?.charAt(0).toUpperCase()}</Avatar>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem disabled>{user?.email}</MenuItem>
              <MenuItem disabled>{`Role: ${userRole}`}</MenuItem> {/* Display the user role */}
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Secondary Navbar */}
      {location.pathname.startsWith('/tbt-os') && (
        <AppBar position="static" color="default">
          <Toolbar sx={{ justifyContent: 'center' }}>
            <Button  
              component={Link} 
              to="/tbt-os/team"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/team') && buttonStyles['&.active']) }}
            >
              Team
            </Button>
            <Button  
              component={Link} 
              to="/tbt-os/companieslist"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/companieslist') && buttonStyles['&.active']) }}
            >
              Companies
            </Button>
            <Button
              component={Link}
              to="/tbt-os/siteslist"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/siteslist') && buttonStyles['&.active']) }}
            >
              Sites
            </Button>
            <Button
              component={Link}
              to="/tbt-os/projects"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/projects') && buttonStyles['&.active']) }}
            >
              Projects
            </Button>
            {/* <Button
              component={Link}
              to="/tbt-os/sections"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/sections') && buttonStyles['&.active']) }}
            >
              Sections
            </Button> */}
            <Button
              component={Link}
              to="/tbt-os/machinelist"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/machinelist') && buttonStyles['&.active']) }}
            >
              Building Machines
            </Button>
            <Button
              component={Link}
              to="/tbt-os/podslist"
              sx={{ ...buttonStyles, ...(isActive('/tbt-os/podslist') && buttonStyles['&.active']) }}
            >
              Pods
            </Button>

            
            
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Navbar;
