// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase';

const ProtectedRoute = ({ element }) => {
  const user = auth.currentUser;

  return user ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;


