// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage


const firebaseConfig = {
  apiKey: "AIzaSyDYYjbX-p7XnaXtuwn6S2iRdUagH_-qQb0",
  authDomain: "tbt-projects-v1-2.firebaseapp.com",
  projectId: "tbt-projects-v1-2",
  storageBucket: "tbt-projects-v1-2.appspot.com",
  messagingSenderId: "937799250044",
  appId: "1:937799250044:web:82dca0a306b20cb93d99f9",
  measurementId: "G-E5M76F5H6X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Firebase Storage


export { auth, firestore, analytics, storage };
